import { TEXT_FONT, TITLE_FONT } from '../../constants';
import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { OverlinedSubTitle } from '../shared';

const StyledRow = styled(Row)`
    h2 {
        font-family: ${TITLE_FONT};
        font-size: 48px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
    }

    div {
        font-family: ${TEXT_FONT};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.1px;
    }

    @media (max-width: 768px) {
        .spaced {
            margin-top: 50px;
        }
    }

    @media (max-width: 992px) {
        margin-top: 20px;
    }
`;

export const TwoRows = ({ children, txtcolor }) => {
    return (
        <Container className="pt-5 pb-5">
            <Row>
                <Col xs={6} xl={2}>
                    <OverlinedSubTitle txtcolor={txtcolor}>Our offices.</OverlinedSubTitle>
                </Col>
            </Row>
            <StyledRow style={{ color: txtcolor }}>{children}</StyledRow>
        </Container>
    );
};

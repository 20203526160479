import { Col, Container, Row } from 'reactstrap';
import React from 'react';
import { PRIMARY_BG_COLOR } from '../../constants';
import styled from 'styled-components';
import { OverlinedSubTitle } from '../shared';
import Link from '../shared/link';

const StyledContainer = styled(Container)`
    padding-bottom: 100px;
    padding-top: 100px;
`;

const ImageCol = styled(Col)`
    img {
        width: 100%;
    }

    @media (min-width: 1200px) {
        img {
            width: 90%;
        }
    }

    margin-top: 100px;
`;

export const ImageGrid = ({ images, urls }) => {
    return (
        <StyledContainer>
            <Row>
                <Col xs={6} xl={2}>
                    <OverlinedSubTitle txtcolor={PRIMARY_BG_COLOR}>Awards.</OverlinedSubTitle>
                </Col>
            </Row>
            <Row>
                <Col xl={9}>
                    <Row>
                        {images.map((image, index) => {
                            return (
                                <ImageCol xs={6} md={3} xl={3} key={index}>
                                    <Link to={urls[index]} key={index} className="w-100">
                                        <img src={image} alt={''} />
                                    </Link>
                                </ImageCol>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        </StyledContainer>
    );
};
